/* eslint-disable indent */

import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'

const RichTextStyles = styled.div`
  /* Fallbacks */
  font-size: ${theme.fonts.size.body};
  font-family: ${theme.fonts.family.body};
  line-height: ${theme.fonts.lineHeight.body};
  letter-spacing: ${theme.fonts.letterSpacing.body};
  /* Prop based font styles (i.e. font="heading2" - only for RichText fields) */
  font-size: ${p => p.font && theme.fonts.size[p.font]}!important;
  font-family: ${p => p.font && theme.fonts.family[p.font]}!important;
  line-height: ${p => p.font && theme.fonts.lineHeight[p.font]}!important;
  letter-spacing: ${p => p.font && theme.fonts.letterSpacing[p.font]}!important;
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  text-transform: ${p => (p.font === 'smallCaps' ? 'uppercase' : '')};
  text-align: ${p => (p.center ? 'center' : 'left')};
  z-index: 1;

  * {
    /* Fallbacks */
    font-size: ${theme.fonts.size.body};
    font-family: ${theme.fonts.family.body};
    line-height: ${theme.fonts.lineHeight.body};
    letter-spacing: ${theme.fonts.letterSpacing.body};
    /* Prop based font styles (i.e. font="heading2" - only for RichText fields) */
    font-size: ${p => p.font && theme.fonts.size[p.font]}!important;
    font-family: ${p => p.font && theme.fonts.family[p.font]}!important;
    line-height: ${p => p.font && theme.fonts.lineHeight[p.font]}!important;
    letter-spacing: ${p => p.font && theme.fonts.letterSpacing[p.font]}!important;
    color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
    text-transform: ${p => (p.font === 'smallCaps' ? 'uppercase' : '')};
    text-align: ${p => (p.center ? 'center' : 'left')};
    z-index: 1;

    a,
    a strong {
      color: ${theme.color.purple500};
    }
  }

  p {
    margin-bottom: 1em;
  }

  pre {
    font-family: ${theme.fonts.family.smallCaps};
    font-size: ${theme.fonts.size.smallBody};
  }

  a[target='_blank'],
  a[target='blank'] {
    ::before {
      content: '(Opens in a new tab)';
      position: absolute;
      left: -9000px;
      width: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      white-space: nowrap;
      font-size: ${theme.fonts.size.tinyBody};
      text-align: center;
      padding: 4px;
      border-radius: 3px;
      background-color: ${theme.color.white};
    }
    &:focus,
    &:hover,
    &:active {
      ::before {
        top: 2em;
        left: 1em;
        width: auto;
        color: ${theme.color.offBlack};
        clip: unset;
        z-index: 9999;
        border: 1px solid ${theme.color.gray400};
        background-color: ${theme.color.white};
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
`

const Text = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.body};
  font-family: ${theme.fonts.family.body};
  line-height: ${theme.fonts.lineHeight.body};
  z-index: 1;

  ${p =>
    p.bodyHeavy &&
    `
    font-size: ${theme.fonts.size.bodyHeavy};
    font-family: ${theme.fonts.family.bodyHeavy};
    line-height: ${theme.fonts.lineHeight.bodyHeavy};
    `};
  ${p =>
    p.largeBody &&
    `
    font-size: ${theme.fonts.size.largeBody};
    font-family: ${theme.fonts.family.largeBody};
    line-height: ${theme.fonts.lineHeight.largeBody};
  `};
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p => p.strikeThrough && `text-decoration: line-through;`}
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
  * {
    color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
    font-size: ${theme.fonts.size.body};
    font-family: ${theme.fonts.family.body};
    line-height: ${theme.fonts.lineHeight.body};
    ${p =>
      p.bodyHeavy &&
      `
    font-size: ${theme.fonts.size.bodyHeavy};
    font-family: ${theme.fonts.family.bodyHeavy};
    line-height: ${theme.fonts.lineHeight.bodyHeavy};
    `};
    ${p =>
      p.largeBody &&
      `
    font-size: ${theme.fonts.size.largeBody};
    font-family: ${theme.fonts.family.largeBody};
    line-height: ${theme.fonts.lineHeight.largeBody};
  `};
    ${p => p.center && `text-align: center;`}
    ${p =>
      p.uppercase &&
      `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
    ${p =>
      p.underline &&
      `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
    ${p =>
      p.strikeThrough &&
      `
    text-decoration: line-through;
  `};
    ${p => p.onClick && `cursor: pointer;`}
    ${p =>
      p.numberOfLines &&
      `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
  a,
  a em,
  a strong,
  li a,
  a p {
      text-decoration: underline;
      text-underline-offset: 4px;
      color: ${theme.color.purple500};

      &:hover {
        color: ${theme.color.white};
      }
    }

    a[target='_blank'],
    a[target='blank'] {
      ::before {
        content: '(Opens in a new tab)';
        position: absolute;
        left: -9000px;
        width: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);
        white-space: nowrap;
        font-size: ${theme.fonts.size.tinyBody};
        text-align: center;
        padding: 4px;
        border-radius: 3px;
        background-color: ${theme.color.white};
      }
      &:focus,
      &:hover,
      &:active {
        ::before {
          top: 2em;
          left: 1em;
          width: auto;
          color: ${theme.color.offBlack};
          clip: unset;
          z-index: 9999;
          border: 1px solid ${theme.color.gray400};
          background-color: ${theme.color.white};
          @media (max-width: 800px) {
            display: none;
          }
        }
      }
    }
  }

  a,
  a em,
  a strong,
  li a,
  a p {
    text-decoration: underline;
    text-underline-offset: 4px;
    color: ${theme.color.purple500};

    &:hover {
      color: ${theme.color.white};
    }
  }
`

const SmallText = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.smallBody};
  font-family: ${theme.fonts.family.smallBody};
  line-height: ${theme.fonts.lineHeight.smallBody};
  ${p =>
    p.smallBody &&
    `
    font-size: ${theme.fonts.size.smallBody};
    font-family: ${theme.fonts.family.smallBody};
    line-height: ${theme.fonts.lineHeight.smallBody};
  `};
  ${p =>
    p.smallBodyHeavy &&
    `
    font-size: ${theme.fonts.size.smallBodyHeavy};
    font-family: ${theme.fonts.family.smallBodyHeavy};
    line-height: ${theme.fonts.lineHeight.smallBodyHeavy};
  `};
  ${p =>
    p.smallBodyBold &&
    `
    font-size: ${theme.fonts.size.smallBodyBold};
    font-family: ${theme.fonts.family.smallBodyBold};
    line-height: ${theme.fonts.lineHeight.smallBodyBold};
  `};
  ${p =>
    p.tinyBody &&
    `
    font-size: ${theme.fonts.size.tinyBody};
    font-family: ${theme.fonts.family.tinyBody};
    line-height: ${theme.fonts.lineHeight.tinyBody};
    `};
  ${p =>
    p.microBody &&
    `
    font-size: ${theme.fonts.size.microBody};
    font-family: ${theme.fonts.family.microBody};
    line-height: ${theme.fonts.lineHeight.microBody};
    `};
  z-index: 1;
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const SmallCaps = styled.p`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  font-size: ${theme.fonts.size.smallCaps};
  font-family: ${theme.fonts.family.smallCaps};
  line-height: ${theme.fonts.lineHeight.smallCaps};
  letter-spacing: ${theme.fonts.letterSpacing.smallCaps};
  text-transform: uppercase;
  z-index: 1;

  ${p =>
    p.smallCapsSans &&
    `
    font-size: ${theme.fonts.size.smallCapsSans};
    font-family: ${theme.fonts.family.smallCapsSans};
    line-height: ${theme.fonts.lineHeight.smallCapsSans};
    letter-spacing: ${theme.fonts.letterSpacing.smallCapsSans};
  `};
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Span = styled.span`
  font-size: ${theme.fonts.size.body};
  font-family: ${theme.fonts.family.body};
  line-height: ${theme.fonts.lineHeight.body};
  font-size: ${p => p.font && theme.fonts.size[p.font]};
  font-family: ${p => p.font && theme.fonts.family[p.font]};
  line-height: ${p => p.font && theme.fonts.lineHeight[p.font]};
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p => p.strikeThrough && `text-decoration: line-through;`}
`

const Heading1 = styled.h1`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  ${p => p.center && `text-align: center;`}
  z-index: 1;
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Heading2 = styled.h2`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Heading3 = styled.h3`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
    `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const Heading4 = styled.h4`
  color: ${p => (p.color ? theme.color[p.color] : theme.color.offBlack)};
  z-index: 1;
  ${p => p.center && `text-align: center;`}
  ${p =>
    p.underline &&
    `
    text-decoration: underline;
    text-underline-offset: 4px;
  `};
  ${p =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: .5px;
  `};
  ${p =>
    p.numberOfLines &&
    `overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${p.numberOfLines};`}
`

const TextComponent = React.forwardRef((props, ref) => {
  let el = <Text {...props} ref={ref} />

  if (props.richText) {
    el = <RichTextStyles {...props} ref={ref} />
  }

  if (props.body) {
    el = <Text {...props} ref={ref} />
  }

  if (props.bodyHeavy) {
    el = <Text bodyHeavy {...props} ref={ref} />
  }

  if (props.largeBody) {
    el = <Text largeBody {...props} ref={ref} />
  }

  if (props.smallBody) {
    el = <SmallText {...props} ref={ref} />
  }

  if (props.smallBodyHeavy) {
    el = <SmallText smallBodyHeavy {...props} ref={ref} />
  }

  if (props.smallBodyBold) {
    el = <SmallText smallBodyBold {...props} ref={ref} />
  }

  if (props.tinyBody) {
    el = <SmallText tinyBody {...props} ref={ref} />
  }
  if (props.microBody) {
    el = <SmallText microBody {...props} ref={ref} />
  }

  if (props.smallCaps) {
    el = <SmallCaps {...props} ref={ref} />
  }

  if (props.smallCapsSans) {
    el = <SmallCaps smallCapsSans {...props} ref={ref} />
  }

  if (props.isSpan) {
    el = <Span {...props} ref={ref} />
  }

  if (props.heading1) {
    el = <Heading1 role="heading" aria-level="1" {...props} ref={ref} />
  }

  if (props.heading2) {
    el = <Heading2 role="heading" aria-level="2" {...props} ref={ref} />
  }

  if (props.heading3) {
    el = <Heading3 role="heading" aria-level="3" {...props} ref={ref} />
  }

  if (props.heading4) {
    el = <Heading4 role="heading" aria-level="4" {...props} ref={ref} />
  }

  if (props.richText) {
    el = <RichTextStyles {...props} ref={ref} />
  }

  return el
})

TextComponent.displayName = 'TextComponent'

export default TextComponent
